<template>
  <div class="manager-container">
    <div class="left">
      <base-block-title
        :title="$t('menu')"
        :sub-title="menus.length">
      </base-block-title>
      <div class="left-content">
        <span
          :class="{active:item.id === currentMenu.id}"
          v-for="item in menus"
          :key="item.id"
          @click="getFunctionList(item)">
          {{ $t(item.name) }}
        </span>
      </div>
    </div>
    <div class="right">
      <div class="title-button">
        <base-block-title
          :title="$t('functionList')"
          :sub-title="currentMenu.name?$t(currentMenu.name):''">
        </base-block-title>
        <div class="button-block">
          <el-button
            v-if="hasPermission($permissionFunctionCode.ADD)"
            type="primary"
            round
            plain
            :disabled="!currentMenu.id"
            @click="addRowData">
            {{ $t('add') }}
          </el-button>
          <!--不要去除下面函数的括号-->
          <el-button
            type="primary"

            round
            plain
            @click="refreshDataMixin()">
            {{ $t('refresh') }}
          </el-button>
        </div>
      </div>
      <div class="table-block">
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          row-key="id"
          default-expand-all
          height="100%"
          stripe
          :default-sort="{prop:'sort'}">
          <base-no-data slot="empty"></base-no-data>
          <el-table-column
            type="index"
            :label="$t('serialNum')"
            align="center"
            :width="50">
          </el-table-column>
          <el-table-column
            prop="name"
            align="center"
            :label="$t('functionName')">
          </el-table-column>
          <el-table-column
            prop="code"
            align="center"
            :label="$t('functionCode')">
          </el-table-column>
          <el-table-column
            prop="enable"
            align="center"
            :label="$t('enable')">
            <template slot-scope="scope">
              <div class="status" :class="scope.row.enable?'enabled-status':'disabled-status'">
                <span></span>
                <span>{{ scope.row.enable ? `${$t('enable')}` : `${$t('disabled')}` }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            align="center"
            :label="$t('createTime')">
          </el-table-column>
          <el-table-column
            align="center"
            fixed="right"
            :label="$t('operate')"
            width="180">
            <template slot-scope="scope">
              <!--删除-->
              <base-button-delete
                v-if="hasPermission($permissionFunctionCode.DELETE)"
                @confirm="deleteRowData(scope.row)">
              </base-button-delete>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
      :visible.sync="menuFunctionFormVisible"
      custom-class="base-form"
      top="10vh"
      width="40%"
      @close="resetMenuFunctionForm">
      <span slot="title" class="base-title-font">
        <em
          :style="{color:$store.state.currentTheme.primaryColor}"
          class="iconfont icon-yuandianzhong">
        </em>
        {{ $t('add') }}
      </span>
      <div>
        <el-form class="body"
          label-width="160px"
          :model="menuFunctionForm"
          :rules="menuFunctionFormRules"
          ref="menuFunctionForm">
          <el-form-item label="Menu" prop="menu">
            <el-select
              v-model="menuFunctionForm.menu"
              value-key="id"
              :disabled="true">
              <el-option
                v-for="option in menus"
                :key="option.id"
                :label="$t(option.name)"
                :value="option">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Function Name" prop="function">
            <el-select
              v-model="menuFunctionForm.function"
              value-key="id"
              @change="onMenuFunctionChange">
              <el-option
                v-for="option in functions"
                :key="option.id"
                :label="option.name"
                :value="option">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Function Code" prop="functionCode">
            <el-input
              v-model="menuFunctionForm.functionCode"
              :disabled="true">
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button

          round
          @click="resetMenuFunctionForm">
          {{ $t('cancel') }}
        </el-button>
        <el-button

          round
          type="primary"
          :loading="loading"
          @click="submitMenuFunctionForm">
          {{ $t('submit') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import baseTableMixin from '@/mixins/base-table-mixin'

export default {
  name: 'FunctionManager',
  mixins: [baseTableMixin],
  data () {
    return {
      menus: [],
      currentMenu: {},
      functions: [],
      menuFunctionFormVisible: false,
      menuFunctionForm: {
        menu: null,
        function: null,
        functionCode: null,
      },
      menuFunctionFormRules: {
        menu: [{ required: true, message: "Please select a menu", trigger: 'change' }],
        function: [{ required: true, message: "Please select a function", trigger: 'change' }],
        functionCode: [],
      },
      loading: false
    }
  },

  computed: {
    functionOptions() {
      return this.functions.map(f => { return { value: f.id, label: f.name }}) || []
    },

    menuOptions() {
      return this.menus.map(m => { return { value: m.id, label: this.$t(m.name) }}) || []
    }
  },

  created () {
    this.queryUrl = this.$apis.queryFunction
    this.saveUrl = this.$apis.saveFunction
    this.deleteUrl = this.$apis.deleteFunction
    this.getMenuList()
    this.getAllFunctions()
  },

  methods: {
    getMenuList () {
      /**
       * 获取菜单列表
       */
      this.$request.post(
        {
          url: this.$apis.queryMenu,
          data: {
            size: -1
          }
        }).then(data => {
        // 返回的数据格式和后端约定
        if (data?.code === 1000) {
          const menus = data.data.records || []
          const menuIds = menus.map(menu => menu.id)
          const menuParentIds = menus.map(menu => menu.parentId)
          this.menus = menus.filter(menu => {
            return menuIds.includes(menu.id) && !menuParentIds.includes(menu.id)
          })
          if (this.menus.length) {
            this.getFunctionList(this.menus[0])
          }
        }
      })
    },

    getAllFunctions() {
      this.$request.post(
        {
          url: this.$apis.queryFunction,
          data: {
            size: -1
          }
        }).then(data => {
          if(data?.code === 1000) {
            this.functions = data.data.records || []
          }
        })
    },

    getFunctionList (menu) {
      this.currentMenu = menu
      this.otherParams = {
        menuId: menu.id
      }
      this.getTableDataMixin()
    },

    addRowData () {
      this.menuFunctionFormVisible = true
      this.menuFunctionForm.menu = this.currentMenu
    },

    deleteRowData (row) {
      /**
       * 删除一行数据
       * @param {Object} row 行数据
       */
      this.$request.post({
        url: this.$apis.unbindFunction,
        data: {
          functionIds: [row.id],
          menuId: this.currentMenu.id
        }
      }).then(data => {
        if (data?.code === 1000) {
          this.$message.success(this.$t('dataDeleteSuccess'))
          this.getFunctionList(this.currentMenu)
        }
      })
    },

    onMenuFunctionChange(selected) {
      this.menuFunctionForm.functionCode = selected.code
    },

    submitMenuFunctionForm() {

      this.$refs['menuFunctionForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          this.$request.post({
            url: this.$apis.bindFunction,
            data: {
              functionIds: [this.menuFunctionForm.function.id],
              menuId: this.menuFunctionForm.menu.id
            }
          }).then(data => {
            if (data?.code === 1000) {
              this.$message.success(this.$t('dataAddSuccess'))
              this.getFunctionList(this.currentMenu)
            }
          }).finally(() => {
            this.loading = false
            this.resetMenuFunctionForm()
          })
        } else {
          return false;
        }
      });
    },

    resetMenuFunctionForm() {
      this.menuFunctionFormVisible = false
      this.$refs['menuFunctionForm'].resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "index";
.base-form {
  .body {
    padding: 20px;
  }
}
</style>
